import axios from "axios";
import qs from "qs";

export const getLastUploadedInfo = async (
  setLastUploadedInfo,
  setExpensesFilters,
  setInitialLoad,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const response = await axios(`expenses/upload-info`);
    setInitialLoad(false);
    setLastUploadedInfo(response.data);
    setExpensesFilters({
      fiscalYear: response.data.lastUploadFiscalYear || "",
      glPeriod: response.data.lastUploadGLPeriod,
    });
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getFiscalYear = async (
  setFiscalYear,
  clearAlert,
  setAlert,
  { size, value }
) => {
  const params = {
    size: size || 10,
    value,
  };
  try {
    clearAlert();
    const response = await axios({
      url: `/expenses/fiscal-years/autocomplete`,
      params,
    });
    setFiscalYear(response.data.values);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getGLPeriod = async (
  //function does not require alert since they have field-level errors
  setGLPeriodOptions,
  setGLPeriod,
  expensesFilters,
  setExpensesFilters,
  pagination,
  setPagination,
  setGLPeriodError,
  { fiscalYear, size, value }
) => {
  const params = {
    fiscalYear: fiscalYear || undefined,
    size: size || 10,
    value: value || undefined,
  };
  try {
    setGLPeriodError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/expenses/gl-periods/autocomplete`,
      params,
    });
    if (response.data.count) {
      setGLPeriodOptions(response.data.values);
      setGLPeriod(
        response.data.values ? response.data.values[0].displayText : ""
      );
      setExpensesFilters({
        ...expensesFilters,
        fiscalYear,
        glPeriod: response.data.values[0].displayText,
      });
      setPagination({
        ...pagination,
        page: 0,
      });
    } else {
      setGLPeriodOptions([]);
      setGLPeriod("");
    }
  } catch (error) {
    setGLPeriodError({
      exist: true,
      message: error.message,
    });
  }
};

export const getInitialExpensesData = async (
  //function does not require alert since they have field-level errors
  setFiscalYearOptions,
  setGLPeriodOptions,
  setGLPeriodError,
  { size, value, fiscalYear }
) => {
  const fiscalYearRequest = {
    url: `/expenses/fiscal-years/autocomplete`,
    params: {
      size,
      value,
    },
  };
  const glPeriodRequest = {
    url: `/expenses/gl-periods/autocomplete`,
    params: {
      size,
      fiscalYear,
    },
  };
  const requests = [fiscalYearRequest, glPeriodRequest];
  try {
    setGLPeriodError({
      exist: null,
      message: null,
    });

    var response = await Promise.all(
      requests.map((request) => axios(request).then((response) => response))
    );

    if (response[0].data.count && response[1].data.count) {
      setFiscalYearOptions(response[0].data.values);
      setGLPeriodOptions(response[1].data.values);
    }
  } catch (error) {
    setGLPeriodError({
      exist: true,
      message: error.message,
    });
  }
};

export const getAllExpenses = async (
  filters,
  pagination,
  sort,
  setExpenses,
  setTotalCount,
  setLoading,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const request = {
      url: `/expenses`,
      params: {
        fiscalYear: filters.fiscalYear || undefined,
        glPeriod: filters.glPeriod || undefined,
        posted: filters.posted || undefined,
        conditionType: filters.conditionType || undefined,
        notification: filters.notification || undefined,
        p: pagination.page + 1,
        ps: pagination.rowsPerPage,
        s: `${sort.column}:${sort.orderBy}`,
      },
    };
    setLoading(true);
    const response = await axios(request);

    setExpenses(response.data.values || []);
    setTotalCount(response.data.totalCount);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const patchNotificationStatus = async (
  id,
  params,
  setLoading,
  setOpenDialog,
  setExpenses,
  expenseIndex,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const notesKey =
      params.notificationStatus.toLowerCase() === "resolved"
        ? "resolvedNote"
        : "inProcessNote";
    const status =
      params.notificationStatus.toLowerCase() === "resolved"
        ? "RESOLVED"
        : "INPROCESS";

    const response = await axios.patch(`/expenses/${id}`, {
      notification: {
        status,
        [notesKey]: params.notes,
      },
    });
    if (response) {
      setExpenses((expenses) => {
        const items = expenses.map((expense, index) =>
          index === expenseIndex
            ? {
                ...expense,
                notification: { ...response.data.notification, status },
              }
            : expense
        );
        return items;
      });
      setOpenDialog(false);
    }
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const getRevalidateAndPost = async (
  setExpenses,
  expenseIndex,
  clearAlert,
  setAlert,
  t,
  { expenseId }
) => {
  try {
    clearAlert();
    const response = await axios(`/expenses/${expenseId}/revalidate-post`);

    const getRevalidateAndPostMessage = (
      messageType,
      pta,
      revalidateAndPostErrorWarnings
    ) => {
      const message = "UploadedExpenses.mainView.revalidateAndPost";
      switch (messageType) {
        case "error":
          setAlert(
            "error",
            `${pta} ${t(
              `${message}.notPosted`
            )} - ${revalidateAndPostErrorWarnings}`
          );
          break;

        case "warning":
          setAlert("warning", `${pta} ${t(`${message}.postedWithWarning`)}`);
          break;
        case "success":
          setAlert(
            "success",
            `${pta} ${t(`${message}.postedWithoutWarnings`)}`,
            true
          );
          break;
        default:
          return "";
      }
    };

    if (response) {
      setExpenses((expenses) => {
        const items = expenses.map((expense, index) =>
          index === expenseIndex
            ? {
                ...expense,

                notification: {
                  ...expense.notification,
                  errorWarnings:
                    (response.data.notification &&
                      response.data.notification.errorWarnings) ||
                    "",
                },
                validateStatus: response.data.validateStatus,
              }
            : expense
        );
        return items;
      });
    }

    getRevalidateAndPostMessage(
      (response.data.validateStatus &&
        response.data.validateStatus.toLowerCase()) ||
        "success",
      response.data.ptaNumber,
      (response.data.notification &&
        response.data.notification.errorWarnings) ||
        ""
    );
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getLastUploadedData = async (
  setExpensesPreference,
  setAlert,
  clearAlert,
  { location }
) => {
  try {
    clearAlert();
    const response = await axios(`expenses/upload-info`);
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    response.data &&
      setExpensesPreference((previousState) => ({
        expenses: {
          ...previousState.expenses,
          filters: {
            ...previousState.expenses.filters,
            fiscalYear: params.fiscalYear || response.data.lastUploadFiscalYear,
            glPeriod: params.glPeriod || response.data.lastUploadGLPeriod,
          },
          lastUploaded: {
            fiscalYear: response.data.lastUploadFiscalYear,
            glPeriod: response.data.lastUploadGLPeriod,
          },
        },
      }));
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getRedistributeExpenses = async (
  setMonthlyExpenseDetails,
  setRedistributeExpenses,
  setRedistributeExpensesCount,
  setSaveRedistributeExpenses,
  setRemainingToDistribute,
  setMTDAmount,
  setLoading,
  clearAlert,
  setAlert,
  t,
  { expenseId }
) => {
  try {
    clearAlert();
    setLoading(true);
    const response = await axios({
      url: `/expenses/${expenseId}/redistrubtes`,
    });

    setMonthlyExpenseDetails(response.data.expense);
    setRedistributeExpenses(response.data.expenses.values || []);
    setRedistributeExpensesCount(response.data.expenses.count);
    setSaveRedistributeExpenses(new Array(response.data.expenses.count));
    setMTDAmount(response.data.expense.mtdAmount);
    setRemainingToDistribute(response.data.expense.mtdAmount);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const postRedistributeExpenses = async (
  id,
  params,
  setExpenses,
  expenseIndex,
  clearAlert,
  setAlert,
  t
) => {
  const obj = params
    .filter((key) => key !== null)
    .map((row, index) => {
      return {
        commitment: { id: row.commitmentId, name: row.commitmentName },
        component: { id: row.componentId, componentName: row.componentName },
        ptaNumber: row.ptaNumber,
        mtdAmount: row.redistributedAmount,
      };
    });
  try {
    clearAlert();
    const response = await axios.post(`/expenses/${id}/redistrubtes`, {
      expenses: { values: obj },
    });

    const getRedistributeMessage = (pta, redistributedStatus) => {
      const message = "UploadedExpenses.mainView.redistributed";
      switch (redistributedStatus) {
        case "warning":
          setAlert(
            "warning",
            `${pta} ${t(`${message}.redistributedWithWarning`)}`
          );
          break;
        case "without_warning":
          setAlert(
            "success",
            `${pta} ${t(`${message}.redistributedWithoutWarnings`)}`,
            true
          );
          break;
        default:
          break;
      }
    };
    if (response) {
      getRedistributeMessage(
        response.data.expense.ptaNumber,
        response.data.redistrbutedStatus?.toLowerCase()
      );

      setExpenses((expenses) => {
        const items = expenses.map((expense, index) =>
          index === expenseIndex ? response.data.expense : expense
        );
        return items;
      });
    }
  } catch (error) {
    setAlert("error", error.message);
  }
};
