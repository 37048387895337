import { useEffect, useState } from "react";
import qs from "qs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Table as MuiTable,
  Paper as MuiPaper,
  Button as MuiButton,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Report as MuiReportIcon,
  Visibility as MuiVisibilityIcon,
  FileDownload as MuiFileDownloadIcon,
} from "@mui/icons-material";
import { useAlerts, useUsers } from "common";
import {
  getCommitmentRequests,
  patchCommitmentRequests,
} from "app/services/requestsService";
import { getBudgetFiscalYear } from "app/services/budgetingService";
import { useRequestsContext } from "app/services/requestsContext";
import { useDownload } from "app/shared/ui/Download";
import { PositionedSnackbar } from "app/shared/ui/SnackBar";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { RequestsCompletionDialog } from "./RequestsCompletionDialog";
import { CommitmentRequestTableRow } from "./CommitmentRequestTableRow";
import { CommitmentRequestTableFooter } from "./CommitmentRequestTableFooter";
import { CommitmentRequestTableHeaders } from "./CommitmentRequestTableHeaders";

/**
 *
 * @returns
 */
export const Requests = () => {
  const { t } = useTranslation();

  const { filters, sort, setSort, pagination, setPagination, getQueryParams } =
    useRequestsContext();
  const { getDocument, downloadInProgress } = useDownload();

  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState(sort.orderBy);
  const [orderBy, setOrderBy] = useState(sort.column);

  const [commitments, setCommitments] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totals, setTotals] = useState({});
  const [openPublishConfirmation, setOpenPublishConfirmation] = useState(false);

  const [budgetFiscalYear, setBudgetFiscalYear] = useState("");

  const [inlineEditRow, setInlineEditRow] = useState(0);

  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  // Open/Close dialog
  const [openDialog, setOpenDialog] = useState(false);

  const defaults = {
    pagination: [10, 25, 50, 100],
  };

  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;
  const { clearAlert, setAlert } = useAlerts();

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (_event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - pagination.page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPagination({
        ...pagination,
        page: 0,
      });
    }
  };

  const onBeforeSend = () => {
    clearAlert();
    setLoading(true);
  };

  const onError = (error) => {
    if (!axios.isCancel(error)) {
      setLoading(false);
      setAlert("error", error.message);
    }
  };
  const onSuccess = (responseData) => {
    setCommitments(responseData.values || []);
    setTotalCount(responseData.totalCount);
    setTotals(responseData.meta);
    setLoading(false);
  };

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();

    if (permissions.VIEW_COMMITMENTS && filters.budgetYear !== "") {
      getCommitmentRequests(
        {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          orderBy,
          order,
          ...getQueryParams(),
        },
        onBeforeSend,
        onError,
        onSuccess,
        cancelSource
      );
      setRefresh(false);
      setInlineEditRow(false);
    }
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [
    pagination,
    orderBy,
    order,
    permissions,
    filters.budgetYear,
    filters.name,
    filters.departmentCode,
    filters.dispositionStatus,
    refresh,
  ]);

  // XXX - refactor to get the fiscal year from the 'meta' property of the requests collection
  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getBudgetFiscalYear(
      () => void 0,
      setBudgetFiscalYear,
      setAlert,
      () => void 0,
      clearAlert,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [setBudgetFiscalYear, setAlert, clearAlert]);

  /**
   * Snackbar Methods
   */
  useEffect(() => {
    if (snackPack.length > 0) {
      if (messageInfo === undefined) {
        // Set a new snack when we don't have an active one
        setMessageInfo({ ...snackPack[0] });
        setSnackPack((prev) => prev.slice(1));
        setOpen(true);
      } else if (open) {
        // Close an active snack when a new one is added
        setOpen(false);
      }
    }
  }, [snackPack, messageInfo, open]);

  const pushSnackbarMessage = (severity, message) => {
    setSnackPack((prev) => [
      ...prev,
      { severity, message, key: new Date().getTime() },
    ]);
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSnackbarExited = () => {
    setMessageInfo(undefined);
  };
  // End Snackbar

  return (
    <>
      <PositionedSnackbar
        key={messageInfo?.key || undefined}
        open={open}
        onClose={handleSnackbarClose}
        message={messageInfo?.message || undefined}
        severity={messageInfo?.severity || "info"}
        TransitionProps={{ onExited: handleSnackbarExited }}
      />

      {/* Confirmation Dialog for publishing Results MuiTable */}
      <ConfirmationDialog
        open={openPublishConfirmation}
        title={
          <>
            <MuiReportIcon
              fontSize={"large"}
              sx={{ verticalAlign: "bottom", marginRight: "5px" }}
            />
            {t(`Requests.mainView.publishConfirmDialog.title`)}
          </>
        }
        message={t(`Requests.mainView.publishConfirmDialog.message`)}
        handleCancel={() => {
          setOpenPublishConfirmation(false);
        }}
        handleOk={() => {
          const cancelSource = axios.CancelToken.source();
          setOpenPublishConfirmation(false);

          patchCommitmentRequests(
            commitments,
            () => {
              setLoading(true);
            },
            onError,
            () => {
              setLoading(false);
              setRefresh(true);
            },
            cancelSource
          );
        }}
        okLabel={t(`Requests.mainView.publishConfirmDialog.okLabel`)}
        cancelLabel={t("globals.form.actionButtons.cancel")}
      />

      <MuiGrid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
        spacing={2}
      >
        {permissions.DOWNLOAD_REQUESTS && commitments?.length > 0 && (
          <MuiGrid item container justifyContent="space-between" xs={12}>
            {/* Publish the Results table to other users */}
            <MuiGrid item>
              <MuiButton
                variant="contained"
                color="success"
                startIcon={<MuiVisibilityIcon />}
                disabled={loading}
                onClick={() => {
                  // Open confirmation dialog
                  setOpenPublishConfirmation(true);
                }}
                sx={{
                  backgroundColor: "#47ac5b",
                  "&:hover": { backgroundColor: "#007c2f" },
                }}
              >
                {t(`Requests.mainView.publishButtonText`)}
              </MuiButton>
            </MuiGrid>

            {/* Export MuiTable as Excel */}
            <MuiGrid item>
              <MuiButton
                variant="outlined"
                color="inherit"
                startIcon={
                  downloadInProgress ? (
                    <MuiCircularProgress size={15} />
                  ) : (
                    <MuiFileDownloadIcon />
                  )
                }
                disabled={!!downloadInProgress || loading}
                onClick={() => {
                  const queryParams = getQueryParams();
                  getDocument(
                    `${axios.defaults.baseURL}/requests/excel?${qs.stringify({
                      commitmentName: queryParams.name,
                      ...queryParams,
                    })}`,
                    "requests_table_export.xlsx"
                  );
                }}
              >
                {t(`Requests.mainView.exportButtonText`)}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        )}

        {permissions.VIEW_REQUESTS && (
          <MuiGrid item xs={12}>
            <MuiTableContainer component={MuiPaper}>
              <MuiTable
                aria-label="Commitments MuiTable"
                stickyHeader
                sx={whiteTableHeaderLabel}
              >
                <CommitmentRequestTableHeaders
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  budgetFiscalYear={budgetFiscalYear}
                />

                <MuiTableBody>
                  {loading && (
                    <MuiTableRow>
                      <MuiTableCell
                        colSpan={getColSpan(permissions, roles)}
                        sx={{ textAlign: "center" }}
                      >
                        <MuiCircularProgress />
                      </MuiTableCell>
                    </MuiTableRow>
                  )}
                  {/* Display message to non commitment admins explaining this years' requests are not yet visible */}
                  {!loading && !commitments.length && !isCommitmentAdmin && (
                    <MuiTableRow>
                      <MuiTableCell colSpan={getColSpan(permissions, roles)}>
                        <MuiTypography
                          variant="body1"
                          color="inherit"
                          align="center"
                          paragraph={false}
                        >
                          <h2>
                            The {budgetFiscalYear} fiscal year commitment
                            requests have not yet been approved. <br />
                            Once finalized, they will appear here.
                          </h2>
                        </MuiTypography>
                      </MuiTableCell>
                    </MuiTableRow>
                  )}
                  {!loading && !commitments.length && isCommitmentAdmin && (
                    <MuiTableRow>
                      <MuiTableCell colSpan={getColSpan(permissions, roles)}>
                        <MuiTypography
                          variant="body1"
                          color="inherit"
                          align="center"
                          paragraph={false}
                        >
                          {t("globals.list.messages.noData")}
                        </MuiTypography>
                      </MuiTableCell>
                    </MuiTableRow>
                  )}

                  {!loading &&
                    commitments.map((row, index) => (
                      <CommitmentRequestTableRow
                        commitment={row}
                        key={index}
                        budgetFiscalYear={budgetFiscalYear}
                        inlineEditRow={inlineEditRow}
                        setInlineEditRow={setInlineEditRow}
                        pushSnackbarMessage={pushSnackbarMessage}
                        setRefresh={setRefresh}
                        setLoading={setLoading}
                        setOpenDialog={setOpenDialog}
                      />
                    ))}
                </MuiTableBody>

                <CommitmentRequestTableFooter
                  budgetFiscalYear={budgetFiscalYear}
                  totals={totals}
                />
              </MuiTable>
              <MuiTablePagination
                rowsPerPageOptions={defaults.pagination}
                component="div"
                count={totalCount}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </MuiTableContainer>
          </MuiGrid>
        )}
      </MuiGrid>

      {openDialog && (
        <RequestsCompletionDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          pushSnackbarMessage={pushSnackbarMessage}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export const getColSpan = (permissions, roles) => {
  let numColumns = 9;
  roles?.includes("COMMITMENT_ADMIN") && numColumns++;
  //permissions.MEMO_COMMITMENT && numColumns++;
  return numColumns;
};

const getIdFromHateoas = (hateoasUrl) => {
  return new URL(hateoasUrl).pathname.split("/").pop();
};

export const getViewUrl = (url) => {
  return `/commitments/${getIdFromHateoas(url)}/view`;
};
export const getEditUrl = (url) => {
  return `/commitments/${getIdFromHateoas(url)}/edit`;
};
