import { createContext, useContext, useEffect, useState } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";

export const RequestsContext = createContext();

export const RequestsProvider = ({ children }) => {
  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [filters, setFilters] = useState({
    budgetYear: params.budgetYear || "",
    name: params.name || "",
    departmentName: params.deptName || "",
    departmentCode: params.deptName
      ? params.deptName.split("(").pop().split(")")[0].trim()
      : "",
    dispositionStatus: params.dispositionStatus || "",
  });

  // State for Sort
  const [sort, setSort] = useState({
    column: "name",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  // State for keeping commitment id for opened component drawer
  const [openCommitmentId, setOpenCommitmentId] = useState(0);

  const getQueryParams = () => {
    return {
      budgetYear: filters.budgetYear ?? "",
      name: filters.name ?? "",
      departmentName: filters.departmentCode ?? "",
      dispositionStatus: filters.dispositionStatus ?? "",
    };
  };

  useEffect(() => {
    // Clear filter states when not on requests tab
    if (location.pathname.split("/")[1] !== "requests") {
      setFilters({
        budgetYear: params.budgetYear || "",
        name: params.name || "",
        departmentName: params.deptName || "",
        departmentCode: params.deptCode || "",
        dispositionStatus: params.dispositionStatus || "",
      });
      setSort({
        column: "name",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 25,
      });
    }
  }, [
    location,
    params.budgetYear,
    params.name,
    params.deptName,
    params.deptCode,
    params.dispositionStatus,
  ]);

  return (
    <RequestsContext.Provider
      value={{
        filters,
        setFilters,
        sort,
        setSort,
        pagination,
        setPagination,
        openCommitmentId,
        setOpenCommitmentId,
        getQueryParams,
      }}
    >
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequestsContext = () => {
  return useContext(RequestsContext);
};
