import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  getCommitmentByMatch,
  getInitialRequestsData,
} from "app/services/requestsService";
import { useRequestsContext } from "app/services/requestsContext";
import { ComponentDispositionTypes } from "app/shared/constants";
import { ResetFilter } from "app/shared/ui/FilterPanel/ResetFilter";

export const RequestsFilter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  // Filter Context
  const { filters, setFilters, pagination, setPagination } =
    useRequestsContext();
  // State variable and its function for Budget Year
  const [budgetYear, setBudgetYear] = useState(filters.budgetYear);
  // State variable and its function for Commitment Name
  const [commitmentName, setCommitmentName] = useState(filters.name);
  // State variable and its function for Department Name
  const [departmentName, setDepartmentName] = useState(filters.departmentName);
  // State variable and its function for Department Code
  const [departmentCode, setDepartmentCode] = useState(filters.departmentCode);
  // State variable and its function for Status
  const [dispositionStatus, setDispositionStatus] = useState(
    filters.dispositionStatus
  );

  // State variable and its function for Budget year Options
  const [budgetYearOptions, setBudgetYearOptions] = useState([]);
  // State variable and its function for Commitment Options
  const [commitmentNameOptions, setCommitmentNameOptions] = useState([]);
  // State variable and its function for Department Options
  const [departmentNameOptions, setDepartmentNameOptions] = useState([]);

  const [budgetYearFilterError, setBudgetYearFilterError] = useState({
    exist: false,
    message: null,
  });

  const [departmentFilterError, setDepartmentFilterError] = useState({
    exist: false,
    message: null,
  });

  const [commitmentNameFilterError, setCommitmentNameFilterError] = useState({
    exist: false,
    message: null,
  });

  // Function to reset the Filter value and re-load Deals data accordingly
  const resetFn = () => {
    const defaultBudgetYearValue = budgetYearOptions[0];
    setBudgetYear(defaultBudgetYearValue.displayText);
    setCommitmentName("");
    setDepartmentName("");
    setDepartmentCode("");
    setDispositionStatus("");
    setFilters({
      budgetYear: defaultBudgetYearValue.displayText,
      name: "",
      departmentName: "",
      departmentCode: "",
      dispositionStatus: "",
    });
    setCommitmentNameOptions([]);
    setDepartmentNameOptions([]);
    setPagination({
      page: 0,
      rowsPerPage: 25,
    });
  };

  // Get initial data to populate the filters. Here we are setting the Budget Year filter.
  useEffect(() => {
    getInitialRequestsData(
      setBudgetYearOptions,
      setBudgetYear,
      filters,
      setFilters,
      setBudgetYearFilterError,
      {
        size: 25,
      }
    );
    // eslint-disable-next-line
  }, []);

  // useEffect to act on the change of Filter Data variables.
  useEffect(() => {
    const params = {
      budgetYear,
      name: commitmentName,
      deptCode: departmentCode,
      dispositionStatus,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
    navigate(`${location.pathname}?${queryParams}`);
  }, [
    budgetYear,
    commitmentName,
    departmentName,
    departmentCode,
    dispositionStatus,
    navigate,
    location.pathname,
  ]);

  return (
    <>
      {/* Commitments Filter function to show MuiAutocomplete inputs and filter data accordingly. */}
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          id="budgetYear"
          options={budgetYearOptions || []}
          getOptionLabel={(option) => option && String(option.displayText)}
          value={{ displayText: budgetYear }}
          onChange={(event, value) => {
            const budgetYear = value ? value.displayText : "";
            setBudgetYear(budgetYear);
            setFilters({
              ...filters,
              budgetYear: value ? value.displayText : "",
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearOnEscape
          clearIcon={false}
          blurOnSelect
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t("Commitments.filterPanelItems.options.budgetYear.label")}
              variant="outlined"
              error={budgetYearFilterError.exist}
              helperText={budgetYearFilterError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          clearIcon={false}
          blurOnSelect
          clearOnEscape
          id="commitmentNameFilter"
          freeSolo
          autoSelect
          options={commitmentNameOptions || []}
          getOptionLabel={(option) => option}
          value={commitmentName}
          loading={loading}
          /**
           * On change event for MuiAutocomplete
           *
           * 1. Setting up the values for Filter via useState variable.
           * */
          onChange={(_e, value) => {
            setCommitmentName(value);
            setFilters({
              ...filters,
              name: value,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          //onInputChange={onChangeSearchQuery}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(
                "Commitments.filterPanelItems.options.commitmentName.label"
              )}
              variant="outlined"
              onChange={(e) => {
                getCommitmentByMatch(
                  e.target.value,
                  "name",
                  budgetYear,
                  setCommitmentNameOptions,
                  setLoading,
                  setCommitmentNameFilterError
                );
              }}
              error={commitmentNameFilterError.exist}
              helperText={commitmentNameFilterError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <MuiAutocomplete
          size="small"
          clearIcon={false}
          blurOnSelect
          clearOnEscape
          id="departmentNameFilter"
          options={departmentNameOptions || []}
          getOptionLabel={(option) => option}
          value={departmentName}
          loading={loading}
          /**
           * On change event for MuiAutocomplete
           *
           * 1. Setting up the values for Filter via useState variable.
           * */
          onChange={(e, value) => {
            const departmentName = value;
            const departmentCode = value
              ? value.split("(").pop().split(")")[0].trim()
              : "";
            setDepartmentName(departmentName);
            setDepartmentCode(departmentCode);
            setFilters({
              ...filters,
              departmentName,
              departmentCode,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={t(
                "Commitments.filterPanelItems.options.departmentName.label"
              )}
              variant="outlined"
              onChange={(e) => {
                getCommitmentByMatch(
                  e.target.value,
                  "org",
                  budgetYear,
                  setDepartmentNameOptions,
                  setLoading,
                  setDepartmentFilterError
                );
              }}
              error={departmentFilterError.exist}
              helperText={departmentFilterError.message}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined" size="small">
        <MuiInputLabel id="statusLabel">
          {t("Commitments.filterPanelItems.options.dispositionStatus.label")}
        </MuiInputLabel>
        <MuiSelect
          labelId="statusSelectLabel"
          label={t(
            "Commitments.filterPanelItems.options.dispositionStatus.label"
          )}
          id="statusFilter"
          value={dispositionStatus || ""}
          onChange={(e) => {
            const statusVal = e.target.value;
            setDispositionStatus(statusVal);
            document.getElementById("statusFilter").blur();
            setFilters({
              ...filters,
              dispositionStatus: statusVal,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {Object.entries(ComponentDispositionTypes).map(
            ([key, value], index) => (
              <MuiMenuItem key={index} value={key}>
                {t(value)}
              </MuiMenuItem>
            )
          )}
        </MuiSelect>
      </MuiFormControl>

      <ResetFilter resetFn={resetFn} />
    </>
  );
};
