import axios from "axios";
import { format, isValid } from "date-fns";

const queryAllCommitments = (params, cancelSource) => {
  return axios({
    url: `/commitments`,
    cancelToken: cancelSource?.token,
    params,
  });
};

export const getAllCommitments = async (
  setCommitments,
  setTotalCount,
  setLoading,
  setAlert,
  clearAlert,
  {
    page,
    rowsPerPage,
    orderBy,
    order,
    dealName,
    departmentValue,
    status,
    project,
    task,
    award,
  }
) => {
  const params = {
    p: page + 1,
    name: dealName,
    departmentName: departmentValue,
    isClosed: status,
    award: award,
    project: project,
    ps: rowsPerPage,
    s: `${orderBy}:${order}`,
    task: task,
  };
  try {
    setLoading(true);
    const response = await queryAllCommitments(params);
    setCommitments(response.data.values || []);
    setTotalCount(response.data.totalCount);
    setLoading(false);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getAllCommitmentNamesLowercase = async (
  setCommitmentNames,
  setLoading,
  setAlert,
  clearAlert,
  t,
  cancelSource
) => {
  const params = {
    vw: "BRIEF",
  };
  try {
    clearAlert();
    setLoading(true);
    const response = await queryAllCommitments(params, cancelSource);
    setCommitmentNames(
      response.data.values &&
        response.data.values.map((i) => i.name.toLowerCase())
    );
    setLoading(false);
  } catch (error) {
    if (!axios.isCancel(error)) {
      if (error.status === 500) handleServerError(setAlert, t, error);
      else {
        setAlert("error", error.message);
      }
    }
  }
};

export const getDepartmentByMatch = async (
  value,
  setDepartmentNameOptions,
  setLoading,
  setDepartmentFilterError
) => {
  setLoading(true);
  const params = {
    matchOn: "org",
    value,
    size: 25,
  };
  try {
    setDepartmentFilterError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/users/me/orgs/autocomplete`,
      params,
    });
    setDepartmentNameOptions(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setDepartmentFilterError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

export const getMyDepartmentsByMatch = async (
  value,
  setDepartmentNameOptions,
  setLoading,
  setDepartmentFilterError
) => {
  setLoading(true);
  const params = {
    matchOn: "org",
    value,
    size: 25,
  };
  try {
    setDepartmentFilterError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/users/me/orgs/autocomplete`,
      params,
    });
    setDepartmentNameOptions(
      response.data.values && response.data.values.map((i) => i.value)
    );
  } catch (error) {
    setDepartmentFilterError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

export const getCommitmentNameByMatch = async (
  value,
  setCommitmentNameOptions,
  setLoading,
  setCommitmentNameFilterError,
  mappingKey = "displayText"
) => {
  setLoading(true);
  const params = {
    matchOn: "name",
    value,
  };
  try {
    setCommitmentNameFilterError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/commitments/autocomplete`,
      params,
    });
    setCommitmentNameOptions(
      response.data.values && response.data.values.map((i) => i[mappingKey])
    );
  } catch (error) {
    setCommitmentNameFilterError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

// Commitment Form
export const getCommitmentByIdSummary = async (
  id,
  stateSetters,
  alert,
  cancelSource
) => {
  return getCommitmentById(id, "summary", stateSetters, alert, cancelSource);
};

export const getCommitmentByIdFull = async (
  id,
  stateSetters,
  alert,
  cancelSource
) => {
  return getCommitmentById(id, "full", stateSetters, alert, cancelSource);
};

export const getCommitmentById = async (
  id,
  viewType,
  { setFormDataResponse, setHasDocument, setDepartmentNameValue, setLoading },
  { setAlert, clearAlert },
  cancelSource
) => {
  try {
    clearAlert();
    setLoading(true);
    const response = await axios(`commitments/${id}?vw=${viewType}`, {
      cancelToken: cancelSource?.token,
    });
    const responseData = response.data;

    if (responseData.components) {
      responseData.components = responseData.components.map((component) => {
        component.startDate = component.startDate || null;
        component.expirationDate = component.expirationDate || null;
        return component;
      });
    }

    // translates the response data to the UI form format and sets the state
    setFormDataResponse({
      name: responseData.name,
      departmentName: {
        orgName: responseData.departmentName,
        orgCode: responseData.orgCode,
      },
      commitmentType: responseData.commitmentType,
      projectGroup: responseData.projectGroup,
      unit: responseData.unit,
      program: responseData.program,
      presentation: responseData.presentation,
      presentationSubcategory: responseData.presentationSubcategory,
      commitmentNotes: responseData.commitmentNotes,
      isClosed: responseData.isClosed ? responseData.isClosed : false,
      withdrawn: responseData.withdrawn ? responseData.withdrawn : false,
      closedNotes: responseData.closedNotes ? responseData.closedNotes : "",
      closedDate: responseData.closedDate
        ? new Date(responseData.closedDate)
        : null,
      memoAmount: responseData.memoAmount ? responseData.memoAmount : 0.0,
      orgCode: responseData.orgCode,
      coInvestmentEntity: {
        displayText: responseData.coInvestmentEntity || "",
      },
      outstandingAmount: responseData.outstandingAmount || 0.0,
      commitmentRequestType: responseData.commitmentRequestType,
      components: responseData.components,
    });
    setHasDocument({
      hasDocs: responseData.hasDocs,
      documentCount: responseData.documentCount,
    });
    setDepartmentNameValue({
      orgName: responseData.departmentName,
      orgCode: responseData.orgCode,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("successfully aborted");
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

export const getOrgByMatch = async (
  parentOrgCode,
  value,
  setOrgOptions,
  setLoading,
  setFieldError
) => {
  setLoading(true);
  const params = {
    matchOn: "org",
    parentOrgCode,
    value,
  };
  try {
    setFieldError("departmentName", "");
    const response = await axios({
      url: `/orgs/autocomplete`,
      params,
    });
    setOrgOptions(
      response.data.values && response.data.values.map((i) => i.value)
    );
  } catch (error) {
    setFieldError("departmentName", error.message);
  } finally {
    setLoading(false);
  }
};

export const getCoInvestmentByMatch = async (
  value,
  setCoInvestmentOptions,
  setLoading,
  setFieldError,
  cancelSource
) => {
  setLoading(true);
  const params = {
    value,
    size: 25,
  };
  try {
    setFieldError("coInvestmentEntity", "");
    const response = await axios({
      url: `/commitments/co-investment/autocomplete`,
      cancelToken: cancelSource?.token,
      params,
    });
    setCoInvestmentOptions(response.data.values || []);
  } catch (error) {
    setFieldError("coInvestmentEntity", error.message);
  } finally {
    setLoading(false);
  }
};

// Project Groups
export const getAllProjectGroups = async (
  setProjectGroups,
  setProjectGroupError,
  cancelSource
) => {
  try {
    setProjectGroupError({
      exist: false,
      message: null,
    });
    const response = await axios(`/commitments/project-groups`, {
      cancelToken: cancelSource?.token,
    });
    setProjectGroups(
      (response.data.values && response.data.values.map((x) => x.value)) || []
    );
  } catch (error) {
    setProjectGroupError({
      exist: true,
      message: error.message,
    });
  }
};

// Units
export const getAllUnits = async (setUnits, setUnitError, cancelSource) => {
  try {
    setUnitError({
      exist: false,
      message: null,
    });
    const response = await axios(`/commitments/unit`, {
      cancelToken: cancelSource?.token,
    });
    setUnits(
      (response.data.values && response.data.values.map((x) => x.value)) || []
    );
  } catch (error) {
    setUnitError({
      exist: true,
      message: error.message,
    });
  }
};

// Programs
export const getAllPrograms = async (
  setPrograms,
  setProgramError,
  cancelSource
) => {
  try {
    setProgramError({
      exist: false,
      message: null,
    });
    const response = await axios(`/commitments/program`, {
      cancelToken: cancelSource?.token,
    });
    setPrograms(
      (response.data.values && response.data.values.map((x) => x.value)) || []
    );
  } catch (error) {
    setProgramError({
      exist: true,
      message: error.message,
    });
  }
};

// Presentations
export const getAllPresentations = async (
  setPresentations,
  setPresentationError,
  cancelSource
) => {
  try {
    setPresentationError({
      exist: false,
      message: null,
    });
    const response = await axios(`/commitments/presentation`, {
      cancelToken: cancelSource?.token,
    });
    setPresentations(
      (response.data.values && response.data.values.map((x) => x.value)) || []
    );
  } catch (error) {
    setPresentationError({
      exist: true,
      message: error.message,
    });
  }
};

// Presentations Subcategories
export const getAllPresentationSubcategories = async (
  setPresentationSubcategories,
  setPresentationSubcategoryError,
  cancelSource
) => {
  try {
    setPresentationSubcategoryError({
      exist: false,
      message: null,
    });
    const params = {
      size: 25,
    };

    const response = await axios({
      url: `/commitments/presentation-subcategories`,
      cancelToken: cancelSource?.token,
      params,
    });
    setPresentationSubcategories(
      (response.data.values && response.data.values.map((x) => x.value)) || []
    );
  } catch (error) {
    setPresentationSubcategoryError({
      exist: true,
      message: error.message,
    });
  }
};

// Post Commitment
export const postCommitment = async (
  values,
  setLoading,
  setFieldError,
  t,
  clearAlert,
  setAlert,
  afterSave,
  cancelSource
) => {
  try {
    clearAlert();
    setLoading(true);
    // translates the UI form values to the post request format
    const postRequestData = {
      ...values,
      closedDate: isValid(values.closedDate)
        ? format(values.closedDate, "MM/dd/yyyy")
        : null,
    };

    await axios.post(`/commitments`, postRequestData, {
      cancelToken: cancelSource?.token,
    });

    afterSave();
  } catch (error) {
    if (!axios.isCancel(error)) {
      if (error.status === 500) handleServerError(setAlert, t, error);
      else {
        if (error.status === 409 && error.code === "RESOURCE_EXISTS") {
          window.scrollTo(0, 0);
          setFieldError(
            "name",
            t(
              `Commitments.create.form.fields.commitmentName.validation.isDuplicate`
            )
          );
          values.coInvestmentEntity = {
            displayText: values.coInvestmentEntity,
          };
        } else {
          setAlert("error", error.message);
        }
      }
    }
  } finally {
    setLoading(false);
  }
};

/**
 * Handles pre-submit transformations on a commitment.
 * @param {object} formData - the data from the form.
 * @param {object} initialData - the initial data used to populate the form.
 * @param {number} currentFiscalYear - the current fiscal year
 * @returns {object} the transformed `formData` object.
 */
export const prepareCommitmentData = (
  formData,
  initialData,
  currentFiscalYear
) => {
  if (!formData.commitmentType) {
    formData.commitmentType = initialData.commitmentType;
  }

  if (formData.memoAmount) {
    formData.memoAmount =
      typeof formData.memoAmount === "string"
        ? Number(formData.memoAmount.replace(/[^0-9.-]+/g, ""))
        : formData.memoAmount;
  }

  if (formData.departmentName.hasOwnProperty("orgName")) {
    formData.departmentName = formData.departmentName.orgName;
  }

  if (
    formData.coInvestmentEntity &&
    formData.presentationSubcategory &&
    formData.presentationSubcategory.name &&
    formData.presentationSubcategory.name.toLowerCase() === "co-investment"
  ) {
    formData.coInvestmentEntity = formData.coInvestmentEntity.displayText;
  } else {
    formData.coInvestmentEntity = null;
  }

  // prepare the components
  if (Array.isArray(formData.components)) {
    formData.components.forEach((component) => {
      if (currentFiscalYear) {
        component.budgetPlan = component.budgetPlan || {};
        component.budgetPlan.budgetFiscalYear = currentFiscalYear;
      }

      if (component.piOrComponentOwner?.displayText) {
        component.piOrComponentOwner = component.piOrComponentOwner.displayText;
      }
      if (component.componentDate) {
        component.componentDate = format(
          new Date(component.componentDate),
          "MM/dd/yyyy"
        );
      }
      if (component.startDate) {
        component.startDate = format(
          new Date(component.startDate),
          "MM/dd/yyyy"
        );
      }
      if (component.expirationDate) {
        component.expirationDate = format(
          new Date(component.expirationDate),
          "MM/dd/yyyy"
        );
      }
      if (component.closedDate) {
        component.closedDate = format(
          new Date(component.closedDate),
          "MM/dd/yyyy"
        );
      }
    });
  }

  return formData;
};

// Put Commitment
export const putCommitment = async (
  values,
  commitmentId,
  setLoading,
  setFieldError,
  t,
  clearAlert,
  setAlert,
  afterSave,
  cancelSource
) => {
  try {
    clearAlert();
    setLoading(true);
    // translate the UI form values to the put request format
    const putRequestData = {
      ...values,
      id: commitmentId,
      closedDate: isValid(values.closedDate)
        ? format(values.closedDate, "MM/dd/yyyy")
        : null,
    };
    await axios.put(`/commitments/${commitmentId}`, putRequestData, {
      cancelToken: cancelSource?.token,
    });

    afterSave();
  } catch (error) {
    if (!axios.isCancel(error)) {
      if (error.status === 500) handleServerError(setAlert, t, error);
      else {
        if (error.status === 409 && error.code === "RESOURCE_EXISTS") {
          window.scrollTo(0, 0);
          setFieldError(
            "name",
            t(
              `Commitments.create.form.fields.commitmentName.validation.isDuplicate`
            )
          );
          values.coInvestmentEntity = {
            displayText: values.coInvestmentEntity,
          };
        } else {
          setAlert("error", error.message);
        }
      }
    }
  } finally {
    setLoading(false);
  }
};

// Delete Commitment
export const deleteCommitment = async (
  commitmentId,
  setLoading,
  t,
  clearAlert,
  setAlert,
  afterSave
) => {
  try {
    clearAlert();
    setLoading(true);
    await axios({
      method: "delete",
      url: `/commitments/${commitmentId}`,
    });

    afterSave();
  } catch (error) {
    if (!axios.isCancel(error)) {
      if (error.status === 500) handleServerError(setAlert, t, error);
      else {
        setAlert("error", error.message);
      }
    }
  }
};

export const getComponentsByCommitmentId = async (
  setLoading,
  setComponents,
  id,
  project,
  task,
  award,
  setAlert,
  cancelSource
) => {
  try {
    const params = {
      award: award,
      project: project,
      task: task,
    };
    setLoading(true);
    const response = await axios({
      url: `/commitments/${id}/components`,
      cancelToken: cancelSource?.token,
      params,
    });
    setComponents(response.data.values || []);
  } catch (error) {
    if (!axios.isCancel(error)) {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

export const getCommitmentProjectByMatch = async (
  value,
  setCommitmentProjectOptions,
  setLoading,
  setCommitmentProjectFilterError
) => {
  const size = 25;
  try {
    setCommitmentProjectFilterError({
      exist: false,
      message: null,
    });
    setLoading(true);
    const response = await axios(
      `/commitments/project/autocomplete?size=${size}&value=${value}`
    );
    setCommitmentProjectOptions(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setCommitmentProjectFilterError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

export const getCommitmentTaskByMatch = async (
  value,
  commitmentTaskOptions,
  commitmentProject,
  setLoading,
  setCommitmentTaskFilterError
) => {
  const size = 25;
  try {
    setCommitmentTaskFilterError({
      exist: false,
      message: null,
    });
    setLoading(true);
    const response = await axios(
      `commitments/project/${commitmentProject}/task/autocomplete?size=${size}&value=${value}`
    );
    commitmentTaskOptions(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setCommitmentTaskFilterError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

export const getCommitmentAwardByMatch = async (
  value,
  commitmentAwardOptions,
  setLoading,
  setCommitmentAwardFilterError
) => {
  const size = 25;
  try {
    setCommitmentAwardFilterError({
      exist: false,
      message: null,
    });
    setLoading(true);
    const response = await axios(
      `/commitments/award/autocomplete?size=${size}&value=${value}`
    );
    commitmentAwardOptions(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setCommitmentAwardFilterError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

export const getComponentWarningDetails = async (
  setComponentWarningDetails,
  commitmentId,
  componentId,
  setAlert,
  clearAlert,
  cancelSource,
  setLoading
) => {
  try {
    clearAlert();
    setLoading(true);
    const response = await axios({
      url: `/commitments/${commitmentId}/components/${componentId}/warning-notification-detail`,
      cancelToken: cancelSource?.token,
    });
    setComponentWarningDetails(response.data.values || []);
  } catch (error) {
    if (!axios.isCancel(error)) {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

// Commitment Documents
export const getAllDocuments = async (
  setDocuments,
  setTotalCount,
  setLoading,
  setCommitmentName,
  setAlert,
  clearAlert,
  { page, rowsPerPage, orderBy, order, commitmentId }
) => {
  const params = {
    p: page + 1,
    ps: rowsPerPage,
    s: `${orderBy}:${order}`,
  };
  try {
    clearAlert();
    setLoading(true);
    const response = await axios({
      url: `/commitments/${commitmentId}/documents`,
      params,
    });
    setDocuments(response.data.documents.values || []);
    setTotalCount(response.data.documents.totalCount);
    setCommitmentName(response.data.name);
    setLoading(false);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const deleteDocument = async (
  commitmentId,
  documentId,
  setRefresh,
  setSnackBar,
  setDeleteDocumentRecord,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    await axios({
      method: "delete",
      url: `/commitments/${commitmentId}/documents/${documentId}`,
    });
    setRefresh((value) => value + 1);
    setSnackBar(true);
    setDeleteDocumentRecord({});
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const postDocument = async (
  values,
  uploadedDocument,
  commitmentId,
  clearAlert,
  setAlert,
  t,
  setFieldError,
  navigate
) => {
  try {
    clearAlert();

    let formData = new FormData();
    formData.set("documentType", values.documentType);
    formData.set("documentDetail", values.documentName);
    formData.set("documentNotes", values.documentNotes);
    formData.set("departmentAdminView", values.departmentAdminView);
    formData.set("uploadedDocument", uploadedDocument);

    await axios({
      method: "post",
      url: `/commitments/${commitmentId}/documents`,
      data: formData,
      timeout: 10000,
    });

    setAlert(
      "success",
      t("Commitments.commitmentDocuments.create.notification.success", {
        documentName: `"${values.documentName.trim()}"`,
      }),
      true
    );

    if (values.documentType === "RECRUITMENT") {
      navigate("/budgeting");
    } else {
      navigate({
        pathname: `/commitments/${commitmentId}/documents/`,
      });
    }
  } catch (error) {
    if (error.status === 409) {
      setFieldError(
        "file",
        t(
          `Commitments.commitmentDocuments.create.form.fields.uploadedDocument.validation.isDuplicate`
        )
      );
    }
  }
};

export const putDocument = async (
  values,
  uploadedDocument,
  commitmentId,
  documentId,
  clearAlert,
  setAlert,
  t,
  setFieldError,
  navigate
) => {
  try {
    clearAlert();

    let formData = new FormData();
    formData.set("documentType", values.documentType);
    formData.set("documentDetail", values.documentName);
    formData.set("documentNotes", values.documentNotes);
    formData.set("departmentAdminView", values.departmentAdminView);
    formData.set("uploadedDocument", uploadedDocument);

    await axios({
      method: "put",
      url: `/commitments/${commitmentId}/documents/${documentId}`,
      data: formData,
      timeout: 10000,
    });

    setAlert(
      "success",
      t("Commitments.commitmentDocuments.edit.notification.success", {
        documentName: `"${values.documentName.trim()}"`,
      }),
      true
    );

    if (values.documentType === "RECRUITMENT") {
      navigate("/budgeting");
    } else {
      navigate({
        pathname: `/commitments/${commitmentId}/documents/`,
      });
    }
  } catch (error) {
    if (error.status === 409) {
      setFieldError(
        "file",
        t(
          `Commitments.commitmentDocuments.create.form.fields.uploadedDocument.validation.isDuplicate`
        )
      );
    }
  }
};

export const patchDocument = async (
  values,
  commitmentId,
  documentId,
  clearAlert,
  setAlert,
  t,
  setFieldError,
  navigate
) => {
  try {
    clearAlert();

    const params = {
      documentType: values.documentType,
      documentDetail: values.documentName,
      documentNotes: values.documentNotes,
      departmentAdminView: values.departmentAdminView,
    };

    await axios({
      method: "patch",
      url: `/commitments/${commitmentId}/documents/${documentId}`,
      data: params,
    });

    setAlert(
      "success",
      t("Commitments.commitmentDocuments.edit.notification.success", {
        documentName: `"${values.documentName.trim()}"`,
      }),
      true
    );

    if (values.documentType === "RECRUITMENT") {
      navigate("/budgeting");
    } else {
      navigate({
        pathname: `/commitments/${commitmentId}/documents/`,
      });
    }
  } catch (error) {
    if (error.status === 409) {
      setFieldError(
        "file",
        t(
          `Commitments.commitmentDocuments.create.form.fields.uploadedDocument.validation.isDuplicate`
        )
      );
    }
  }
};

export const downloadDocument = async (
  commitmentId,
  documentId,
  setDownloadDataResponse,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const response = await axios({
      url: `/commitments/${commitmentId}/documents/${documentId}/download`,
      responseType: "blob",
    });
    setDownloadDataResponse(response.data);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getDocumentById = async (
  CommitmentId,
  documentId,
  setFormDataResponse,
  setCommitmentName,
  setUploadedDocument,
  setAlert,
  clearAlert,
  cancelSource
) => {
  try {
    clearAlert();
    const response = await axios(
      `commitments/${CommitmentId}/documents/${documentId}`,
      { cancelToken: cancelSource?.token }
    );
    const responseData = response.data;
    setFormDataResponse({
      documentType: responseData.documentType,
      documentName: responseData.documentDetail,
      documentNotes: responseData.documentNotes,
      departmentAdminView: responseData.departmentAdminView,
      file: responseData.fileName,
      uploadedBy: responseData.uploadedBy,
      uploadedDate: responseData.uploadedDate,
    });
    setCommitmentName(responseData.commitmemtName);
    setUploadedDocument({ name: responseData.fileName });
  } catch (error) {
    if (!axios.isCancel(error)) {
      setAlert("error", error.message);
    }
  }
};

export const getDocumentBrief = async (
  CommitmentId,
  setCommitmentName,
  setAlert,
  clearAlert,
  cancelSource
) => {
  try {
    clearAlert();
    const response = await axios(`commitments/${CommitmentId}?vw=brief`, {
      cancelToken: cancelSource?.token,
    });
    setCommitmentName(response.data.name);
  } catch (error) {
    if (!axios.isCancel(error)) {
      setAlert("error", error.message);
    }
  }
};

export function getCurrentFiscalYear() {
  const now = new Date();
  let year = now.getFullYear();
  if (now.getMonth() > 8) {
    year += 1;
  }
  return year;
}

const handleServerError = (setAlert, t, error) => {
  setAlert("error", t("common:apiErrorDefaultMessages.REQUEST_ERROR"));
  if (error?.response?.data?.message) {
    console.error(error.response.data.message);
  }
};
