import { createContext, useContext, useEffect, useState } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { getLastUploadedInfo } from "app/services/transfersService";
import { useAlerts } from "common";

export const TransfersContext = createContext();

export const TransfersProvider = ({ children }) => {
  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State variable to store last uploaded info to display and set as default filters
  const [lastUploadedInfo, setLastUploadedInfo] = useState("");

  // State for filters
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [filters, setFilters] = useState({
    fiscalYear: params.fiscalYear || "",
    glPeriod: params.glPeriod || "",
    posted: params.posted || "",
    conditionType: params.conditionType || "",
    notification: params.notification || "",
    journalSource: params.journalSource || "",
  });

  // State for Sort
  const [sort, setSort] = useState({
    column: "validateStatus",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const [initialLoad, setInitialLoad] = useState(true);

  const { clearAlert, setAlert } = useAlerts();

  // React useEffect with dependency of page change (via history and location object)
  useEffect(() => {
    // "If" condition to run only when user moves out of `transfers` menu
    if (location.pathname.split("/")[1] !== "transfers") {
      // Resetting context
      setFilters({
        fiscalYear: lastUploadedInfo.lastUploadFiscalYear,
        glPeriod: lastUploadedInfo.lastUploadGLPeriod,
        posted: "",
        conditionType: "",
        notification: "",
        journalSource: "",
      });
      setSort({
        column: "validateStatus",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 10,
      });
    }
  }, [location, params.fiscalYear, params.glPeriod, lastUploadedInfo]);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "transfers") {
      getLastUploadedInfo(
        setLastUploadedInfo,
        setFilters,
        setInitialLoad,
        clearAlert,
        setAlert
      );
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <TransfersContext.Provider
      value={{
        filters,
        setFilters,
        sort,
        setSort,
        pagination,
        setPagination,
        lastUploadedInfo,
        setLastUploadedInfo,
        initialLoad,
      }}
    >
      {children}
    </TransfersContext.Provider>
  );
};

export const useTransfersContext = () => {
  return useContext(TransfersContext);
};
