import { Fragment } from "react";
import {
  List as MuiList,
  Grid as MuiGrid,
  Divider as MuiDivider,
  Popover as MuiPopover,
  ListItem as MuiListItem,
  Typography as MuiTypography,
  ListItemText as MuiListItemText,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Theme } from "common";
import { ExpensesNotification } from "app/shared/constants";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";

// Popover for warning details when hovering over the warning icon under the components table for a commitment
export const WarningDetailsPopover = ({
  loading,
  anchorEl,
  uploadTransferStr,
  uploadExpensesStr,
  openWarningPopover,
  handlePopoverClose,
  componentWarningDetails,
  commitmentComponentTableColumnsStr,
}) => {
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  return (
    <MuiPopover
      open={open}
      id="mouse-over-popover"
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      <MuiList
        sx={{
          width: "515px",
          background: Theme.palette.grey[700],
          color: Theme.palette.common.white,
        }}
      >
        <MuiListItem alignItems="center" dense>
          <MuiListItemText
            disableTypography
            primary={
              <MuiGrid container>
                <MuiGrid item xs={12}>
                  <MuiTypography align="center">
                    {t(
                      `${commitmentComponentTableColumnsStr}.componentWarningPopOver.warningLabel`
                    )}
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
            }
          />
        </MuiListItem>
      </MuiList>
      {loading && (
        <MuiGrid align="center" minHeight="80px" alignContent="center">
          <CircularProgress />
        </MuiGrid>
      )}
      {!loading && componentWarningDetails.length === 0 && (
        <MuiGrid align="center" minHeight="80px" alignContent="center">
          <MuiTypography>{t("globals.list.messages.noData")}</MuiTypography>
        </MuiGrid>
      )}
      {componentWarningDetails.length > 0 &&
        componentWarningDetails.map(
          (warningDetailsRow, warningDetailsIndex) => (
            <Fragment key={warningDetailsIndex}>
              <MuiList>
                <MuiListItem alignItems="flex-start" dense>
                  <MuiListItemText
                    disableTypography
                    secondary={
                      <MuiGrid container>
                        <MuiGrid item xs={3}>
                          <MuiTypography variant="body2" color="textSecondary">
                            {t(
                              "Commitments.financialHistory.mainView.list.glPeriodTable.tableColumns.glPeriod"
                            )}
                            :
                          </MuiTypography>
                        </MuiGrid>
                        <MuiGrid item xs={9}>
                          <MuiTypography
                            variant="subtitle1"
                            color="textSecondary"
                          >
                            {warningDetailsRow.glPeriod}
                          </MuiTypography>
                        </MuiGrid>
                      </MuiGrid>
                    }
                  />
                </MuiListItem>
                <MuiListItem alignItems="flex-start" dense>
                  <MuiListItemText
                    disableTypography
                    secondary={
                      <MuiGrid container>
                        <MuiGrid item xs={3}>
                          <MuiTypography variant="body2" color="textSecondary">
                            {t(
                              `${uploadTransferStr}.redistributeTransfersDialog.tableColumns.monthlyTransferDetail.source`
                            )}
                            :
                          </MuiTypography>
                        </MuiGrid>
                        <MuiGrid item xs={9}>
                          <MuiTypography
                            variant="subtitle1"
                            color="textSecondary"
                          >
                            {warningDetailsRow.source}
                          </MuiTypography>
                        </MuiGrid>
                      </MuiGrid>
                    }
                  />
                </MuiListItem>
                <MuiListItem alignItems="flex-start" dense>
                  <MuiListItemText
                    disableTypography
                    secondary={
                      <MuiGrid container>
                        <MuiGrid item xs={3}>
                          <MuiTypography variant="body2" color="textSecondary">
                            {t(`${uploadTransferStr}.tableColumns.amount`)}:
                          </MuiTypography>
                        </MuiGrid>
                        <MuiGrid item xs={9}>
                          <MuiTypography
                            variant="subtitle1"
                            color="textSecondary"
                          >
                            {warningDetailsRow.amount !== undefined &&
                              CurrencyFormat(warningDetailsRow.amount)}
                          </MuiTypography>
                        </MuiGrid>
                      </MuiGrid>
                    }
                  />
                </MuiListItem>
                <MuiListItem alignItems="flex-start">
                  <MuiListItemText
                    disableTypography
                    secondary={
                      <MuiGrid container>
                        <MuiGrid item xs={3}>
                          <MuiTypography variant="body2" color="textSecondary">
                            {t(
                              `${commitmentComponentTableColumnsStr}.componentWarningPopOver.warningLabel`
                            )}
                            :
                          </MuiTypography>
                        </MuiGrid>
                        <MuiGrid item xs={9}>
                          {warningDetailsRow?.warnings?.map(
                            (anObjectMapped, index) => {
                              return (
                                <MuiTypography
                                  variant="subtitle1"
                                  color="textSecondary"
                                  key={index}
                                >
                                  {anObjectMapped}
                                </MuiTypography>
                              );
                            }
                          )}
                        </MuiGrid>
                      </MuiGrid>
                    }
                  />
                </MuiListItem>
                <MuiListItem alignItems="flex-start" padding={0}>
                  <MuiListItemText
                    disableTypography
                    secondary={
                      <MuiGrid container>
                        <MuiGrid item xs={12}>
                          <MuiTypography
                            variant="subtitle1"
                            color="textSecondary"
                          >
                            {t(
                              ExpensesNotification[
                                warningDetailsRow.notificationStatus
                              ]
                            )}{" "}
                            {t(
                              `${uploadExpensesStr}.notificationStatusTooltip.by`
                            )}{" "}
                            {warningDetailsRow.notificationStatus ===
                              "UNRESOLVED" && warningDetailsRow.unresolvedBy}
                            {warningDetailsRow.notificationStatus ===
                              "INPROCESS" && warningDetailsRow.inprocessBy}{" "}
                            {t(
                              `${uploadExpensesStr}.notificationStatusTooltip.on`
                            )}{" "}
                            {warningDetailsRow.notificationStatus ===
                              "UNRESOLVED" && warningDetailsRow.unresolvedDate}
                            {warningDetailsRow.notificationStatus ===
                              "INPROCESS" && warningDetailsRow.inprocessDate}
                          </MuiTypography>
                        </MuiGrid>
                      </MuiGrid>
                    }
                  />
                </MuiListItem>
              </MuiList>
              <MuiDivider />
            </Fragment>
          )
        )}
    </MuiPopover>
  );
};
